import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/admin/contents";

export const adminContentsApiSlice = createApi({
  reducerPath: "adminContentsApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 컨텐츠 리스트 불러오기
    getContentsList : builder.mutation({
      query : (param : {page: any, size : number, keyword: string, sortOrder : string, sortColumn : string}) => {
        return{
          url : `/getContentLists.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),
    // 컨텐츠 저장
    setContents : builder.mutation({
        query : (body : any) => {
            return{
                url : '/setContents.php' , method : 'post', body
            }
        }
    }),
    // 컨텐츠 수정
    uptContents : builder.mutation({
        query : (body : any) => {
            return{
                url : '/uptContents.php', method : 'post', body
            }
        }
    }),
    //컨텐츠 상세보기
    getContentsDetail : builder.mutation({
        query : (param : {idx : string}) => {
            return{
                url : `/getContentDetails.php?idx=${param.idx}`
            }
        }
    })
  }),
});

export const {
  useGetContentsListMutation, useSetContentsMutation, useUptContentsMutation, useGetContentsDetailMutation
} = adminContentsApiSlice;