import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import { useSetToastFileUploadMutation } from "../../store/admin/adminProductApiSlice";

export interface Props {initData : string, setData : Function}
export const ToastUIEditor:React.FC<Props> = ({initData, setData}) => {
    const editorRef = useRef<any>(null)
    const [setToastFileUploadApi] = useSetToastFileUploadMutation()
    const handleChange = () => {
        setData((prev:any)=>({...prev, icons : editorRef.current.getInstance().getMarkdown()}))
    }
    const onUploadImage = async (blob:any, callback:any) => {
        const formData = new FormData()
        formData.append('file', blob)
        const result : any = await setToastFileUploadApi(formData)
        const imageUrl = result.data
        callback(imageUrl?.imageUrl, blob.name)
        return false;
    };
    
    useEffect(()=>{
        if(initData){
            editorRef.current.getInstance().setMarkdown(initData)
        }
    }, [initData])
    
    return(
        <>
        <Editor
            ref={editorRef}
            initialValue={initData}
            onChange={handleChange}
            previewStyle="vertical"
            height="600px"
            initialEditType="markdown"
            hideModeSwitch={true}
            useCommandShortcut={false}
            plugins={[colorSyntax]}
            hooks={{ addImageBlobHook: onUploadImage,
            }}
        />
        </>
    )
}