import React, { useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Blog } from "./blog/list-blog";
import { BlogView } from "./blog/view-blog";
import { FineVu } from "./fineVu/fineVu";
import { Location } from "./location";
import { ProductComparison } from "./productComparison/product-comparison";
import { ProductsAllItem } from "./products/all-items";
import { ProductsDetail } from "./products/detail-item";
import { SiteMap } from "./sitemap";
import { DownloadDashcam } from "./support/downloads/download-dashcam";
import { DownloadPcPlayer } from "./support/downloads/download-pcPlayer";
import { DownloadWifiApp } from "./support/downloads/download-wifiApp";
import { DownloadDashCamView } from "./support/downloads/view-downloadDashcam";
import { WarrentyContact } from "./support/warrentyService/contact";
import { WarrentyMyDashcam } from "./support/warrentyService/login";
import { WarrentyServicePolicy } from "./support/warrentyService/policy";
import { WarrentyServiceProductRegistration } from "./support/warrentyService/productRegistration";
import { MyDashcamView } from "./support/warrentyService/view/view-mydashcam";
import { RegistrationView } from "./support/warrentyService/view/view-registration";
import { FindEmail } from "./support/warrentyService/view/view-findEmail";
import { FindPassword } from "./support/warrentyService/view/view-findPassword";
import { ChangePassword } from "./support/warrentyService/view/view-changePw";
import { PrivacyPolicy } from "./privacyPolicy";

export const SubPage = () => {
    const {productId , id, blogId} = useParams()
    const location = useLocation()
    const [className, setClassName] = useState<string>('')
    useLayoutEffect(()=>{
        switch(location.pathname){
            case '/products' : setClassName('products all_items');break;
            case `/products/${productId}` : setClassName('products product_view');break;
            case '/productComparison' : setClassName('product_comparison'); break;
            case '/support/download/dashcam' : setClassName('support fineVu_dashcam'); break;
            case `/support/download/dashcam/${id}` : setClassName('support fineVu_dashcam_view'); break;
            case '/support/download/wifiapp' : setClassName('support fineVu_wifiApp'); break;
            case '/support/download/pcplayer' : setClassName('support fineVu_pcPlayer'); break;
            case '/support/warrentyService/prRegistration' : setClassName('support fineVu_productRegistration'); break;
            case '/support/warrentyService/prRegistration/regist' : setClassName('support fineVu_yourDashcam'); break;
            case '/support/warrentyService/policy' : setClassName('support fineVu_warrantyPolicy'); break;
            case '/support/warrentyService/myDashcam' : setClassName('support fineVu_myDashcam'); break;
            case `/support/warrentyService/myDashcam/${id}` : setClassName('support fineVu_myDashcam_view'); break;
            case '/support/warrentyService/contact' : setClassName('support getInTouch'); break;
            case '/blog' : setClassName('blog search'); break;
            case `/blog/${blogId}` : setClassName('support blog blog_view'); break;
            case '/fineVu' : setClassName('fineVu'); break;
            case '/location' : setClassName('whereToBuy'); break;
            case '/sitemap' : setClassName('siteMap'); break;
            case '/support/warrentyService/findEmail' : case '/support/warrentyService/findPassword' : case '/support/warrentyService/changePassword' :
            setClassName('support fineVu_myDashcam'); break;
            case '/privacy-policy' : setClassName('privacyPolicy'); break;
        }
    }, [location])

    return(
        <>
        <div className={`subPage ${className}`}>

            {location.pathname === '/products' ? <ProductsAllItem/> : ''}
            {location.pathname === `/products/${productId}` ? <ProductsDetail/> : ''}
            {location.pathname === `/productComparison` ? <ProductComparison/> : ''}
            {location.pathname === `/support/download/dashcam` ? <DownloadDashcam/> : ''}
            {location.pathname === `/support/download/dashcam/${id}` ? <DownloadDashCamView/> : ''}
            {location.pathname === `/support/download/wifiapp` ? <DownloadWifiApp/> : ''}
            {location.pathname === `/support/download/pcplayer` ? <DownloadPcPlayer/> : ''}
            {location.pathname === `/support/warrentyService/prRegistration` ? <WarrentyServiceProductRegistration/> : ''}
            {location.pathname === `/support/warrentyService/prRegistration/regist` ? <RegistrationView/> : ''}
            {location.pathname === `/support/warrentyService/policy` ? <WarrentyServicePolicy/> : ''}
            {location.pathname === `/support/warrentyService/myDashcam` ? <WarrentyMyDashcam/> : ''}
            {location.pathname === `/support/warrentyService/myDashcam/${id}` ? <MyDashcamView/> : ''}
            {location.pathname === `/support/warrentyService/contact` ? <WarrentyContact/> : ''}
            {location.pathname === `/blog` ? <Blog/> : ''}
            {location.pathname === `/blog/${blogId}` ? <BlogView/> : ''}
            {location.pathname === `/fineVu` ? <FineVu/> : ''}
            {location.pathname === `/location` ? <Location/> : ''}
            {location.pathname === `/sitemap` ? <SiteMap/> : ''}
            {location.pathname === '/support/warrentyService/findEmail' ? <FindEmail/> : ''}
            {location.pathname === '/support/warrentyService/findPassword' ? <FindPassword/> : ''}
            {location.pathname === '/support/warrentyService/changePassword' ? <ChangePassword/> : ''}
            {location.pathname === '/privacy-policy' ? <PrivacyPolicy/> : ''}

        </div>
        </>
    )
}