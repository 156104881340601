import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetProducrtByAllMutation } from "../../../../../store/user/userProductApiSlice";

export const DownloadDashcam = () => {
    const navigate = useNavigate()
    const [getProductByAll] = useGetProducrtByAllMutation()
    const [data, setData] = useState([])
    const getList = async() => {
        const param = {channelType: '', resolutionType : '', featuresType : '', page : 1, size : 99}
        const result : any = await getProductByAll(param)
        if(result.data.Result==='true') setData(result.data.List)
    }
    useEffect(()=>{getList()}, [])

    return(
        <>
        <div className="contentBox">
            <h2>
                Download
                <span>FineVu Dashcam</span>
            </h2>
            <p>Firmware · Manual · Speed Cam Data</p>
            <ul>
                {data?.map((list:any)=>(
                <li key={list?.productId} onClick={()=>navigate(`/support/download/dashcam/${list?.productId}`)}>
                    <div className="thumbBox">
                        <img src={list?.productWhiteImageFile} alt="GX4K"/>
                    </div>
                    <span>{list?.productName}</span>
                </li>
                ))}
            </ul>

        </div>
        </>
    )
}