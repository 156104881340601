import React, { useRef, useState } from "react";
import { ManualBox } from "../manualsBox/products-manualsBox";
export const tabList = [
    {idx: 1, title : 'Features'},
    {idx: 2, title : 'Description'},
    {idx: 3, title : "What's In The Box"},
    {idx: 4, title : 'Specifications'},
    {idx: 5, title : 'Manuals & Firmware'},
    {idx: 6, title : "User's Review"},
]
export const GX400 = () => {
    const [state, setState] = useState<any>({first : 1, second : 1})
    const [tabIndex, setTabIndex] = useState<number>(1)
    const scrollRef = [
        useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)
    ];
    const handleScroll = (ref : number) => {
        if(scrollRef[ref] && scrollRef[ref].current){
            scrollRef[ref].current.scrollIntoView({
                behavior : 'smooth'
            })
        }
    }
    return(
        <>
        <div className="product_view gx400">
            <div className="bgBox" style={{backgroundImage: "url('/assets/images/banner_GX400.png')"}}>
                <div className="centerBox">
                    <h2><span>GX</span>400</h2>
                    <p>PERFECT VISIBILITY, QHD DASHCAM</p>
                    <ul>
                        <li onClick={()=>handleScroll(0)}>NON-LCD</li>
                        <li onClick={()=>handleScroll(1)}>Built-in GPS & Wi-Fi</li>
                        <li onClick={()=>handleScroll(2)}>QHD/FHD</li>
                        <li onClick={()=>handleScroll(3)}>HDR</li>
                        <li onClick={()=>handleScroll(4)}>SONY STARVIS Sensor</li>
                        <li onClick={()=>handleScroll(5)}>Auto Night Vision</li>
                        <li onClick={()=>handleScroll(6)}>Power Saving Parking Mode</li>
                        <li onClick={()=>handleScroll(7)}>Smart Time Lapse</li>
                        <li onClick={()=>handleScroll(8)}>ADAS PLUS</li>
                        <li onClick={()=>handleScroll(9)}>Battery Protection</li>
                        <li onClick={()=>handleScroll(10)}>AI Heat Monitoring</li>
                        <li onClick={()=>handleScroll(11)}>Speed Camera Alert</li>
                    </ul>
                </div>

                <div className="scrollDownBox">
                    <div>
                        <span></span>
                    </div>
                    <span>SCROLL</span>
                </div>
            </div>
            {/* <!-- bgBox Fin --> */}
            
            <div className="tabWrap">
                <div className="tabBtnWrap">
                    <div className="tabBtnBox">
                        {tabList.map((list:{idx:number,title:string})=>(
                            <button className={tabIndex===list?.idx?'active':''} onClick={()=>setTabIndex(list?.idx)}>
                                {list?.title}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={`features ${tabIndex === 1? 'active' : ''}`}>
                        <div className="sliderWrap">
                            <ul>
                                <li>
                                    <div className="imgBox">
                                        <img src="/assets/images/view_GX4K_1.png" alt="GX4K"/>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- slider fin --> */}
                            <div className="dotsBox">
                                <span></span>
                                <span className="active"></span>
                                <span></span>
                            </div>
                        </div>
                        {/* <!-- sliderArea Fin --> */}

                        <div className="iconBox">
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/NON_LCD.png" alt="NON-LCD"/>
                                    </div>
                                    <span>NON-LCD</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/Built_inGPS.png" alt="Built-in GPS&Wi-Fi"/>
                                    </div>
                                    <span>
                                        Built-in<br/>
                                        GPS&Wi-Fi
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/QHDFHD.png" alt="QHD/FHD"/>
                                    </div>
                                    <span>QHD/FHD</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/hdr_icon.png" alt="HDR"/>
                                    </div>
                                    <span>HDR</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/Sony_STARVIS.png" alt="SONY STARVIS Sensor"/>
                                    </div>
                                    <span>
                                        SONY STARVIS<br/>
                                        Sensor
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/AutoNightVision.png" alt="Auto Night Vision"/>
                                    </div>
                                    <span>
                                        Auto<br/>
                                        Night Vision
                                    </span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/PowerSavingParkingMode.png" alt="Power Saving Parking Mode"/>
                                    </div>
                                    <span>
                                        Power Saving<br/>
                                        Parking Mode
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/SmartTimeLapse.png" alt="Smart Time Lapse"/>
                                    </div>
                                    <span>
                                        Smart<br/>
                                        Time Lapse
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/AbsoluteParkingMode.png" alt="Absolute Parking Mode"/>
                                    </div>
                                    <span>
                                        Absolute<br/>
                                        Parking Mode
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/ADASPLUS.png" alt="ADAS PLUS"/>
                                    </div>
                                    <span>ADAS PLUS</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/BatteryProtection.png" alt="Battery Protection"/>
                                    </div>
                                    <span>
                                        Battery<br/>
                                        Protection
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/AIHeatMonitoring.png" alt="AI Heat Monitoring"/>
                                    </div>
                                    <span>
                                        AI Heat<br/>
                                        Monitoring
                                    </span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/SpeedCameraAlert.png" alt="Speed Camera Alert"/>
                                    </div>
                                    <span>
                                        Speed Camera<br/>
                                        Alert
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- iconBox Fin --> */}

                        <div className="imgBox">
                            <img src="/assets/images/productView_quad.png" alt="product view"/>
                        </div>

                        <div className="resolution_tableWrap">
                            <ul>
                                <li>
                                    <span>Definition</span>
                                    <span>FULL HD</span>
                                    <span>GX400 <span>QUAD HD</span></span>
                                </li>
                                <li>
                                    <span>Resolution</span>
                                    <span>1920 × 1080</span>
                                    <span>2560 × 1440</span>
                                </li>
                                <li>
                                    <span>Pixels</span>
                                    <span>2,073,600</span>
                                    <span>3,686,400</span>
                                </li>
                            </ul>
                        </div>

                        <section className="section_1" ref={scrollRef[0]}>
                            <h3>
                                <span>NON-LCD</span>
                                COMPACT NON-LCD DASHCAM
                            </h3>
                            <p>The compact design that does not block the driver's view makes it easy to fit in any position.</p>

                            <div className="imgBox">
                                <img src="/assets/images/gx400_section1.png" alt="COMPACT NON-LCD DASHCAM"/>
                            </div>
                        </section>

                        <section className="section_2" ref={scrollRef[1]}>
                            <h3>
                                <span>Built-in Wi-Fi</span>
                                CHECK THE RECORDED VIDEOS AND UPDATE<br/>
                                THE FIRMWARE ON FINEVU APP
                            </h3>
                            <div className="imgBox">
                                <img src="/assets/images/detail_Easy_Wi-Fi.png" alt="Built-in Wi-Fi"/>
                            </div>
                            <ul>
                                <li>* This product is built-in Wi-Fi and does not require a separate Wi-Fi dongle.</li>
                                <li>* The FineVu Wi-Fi app can be downloaded from the Google Play Store / App Store.</li>
                                <li>* For detailed connection method, please refer to the manual.</li>
                            </ul>
                        </section>

                        <section className="section_3">
                            <h3>
                                <span>BUILT-IN GPS</span>
                                FEEL FREE TO USE FUNCTIONS SUCH AS SPEED CAMERA<br/>
                                ALERT AND ADAS PLUS WITHOUT THE BURDEN OF<br/>
                                PURCHASING ADDITIONAL EXTERNAL GPS
                            </h3>

                            <div className="imgBox">
                                <img src="/assets/images/gx400_section3.png" alt="BUILT-IN GPS"/>
                            </div>
                        </section>

                        <section className="section_4" ref={scrollRef[2]}>
                            <h3>
                                <span>Crystal Clear QHD Resolution</span>
                                QHD CAPTURES EVERY MOMENT REALISTICALLY
                            </h3>

                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.first}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 1}))}>
                                        <span>Front</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 2}))}>
                                        <span>Rear</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tabContentBox">
                                <div className={state.first === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx400_section4_front.png" alt="front"/>
                                    </div>
                                    <p>This photo was taken in QHD quality. The license plate was mosaicized to protect personal information.</p>
                                </div>
                                <div className={state.first === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx400_section4_rear.png" alt="rear"/>
                                    </div>
                                    <p>
                                        This photo was taken in QHD quality. The license plate was mosaicized to protect personal information.
                                        <mark>* The quality of recording may vary depending on conditions. (including camera angle, windshield conditions, and light conditions)</mark>
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="section_5" ref={scrollRef[3]}>
                            <h3>
                                <span>HDR</span>
                                OPTIMIZED WITH HIGH DYNAMIC RANGE(HDR),<br/>
                                EXPOSURE IS BALANCED KEEPING ALL THE DETAILS<br/>
                                INTACT
                            </h3>
                            <div className="imgBox">
                                <img src="/assets/images/gx400_section5.png" alt="HDR"/>
                            </div>
                        </section>

                        <section className="section_6" ref={scrollRef[4]}>
                            <h3>
                                <span>SONY STARVIS Sensor</span>
                                SONY STARVIS IMX335 PROVIDES UNIMAGINABLY VIVID<br/>
                                IMAGE IN REGARDLESS OF DAY OR NIGHT
                            </h3>
                            <div className="imgBox">
                                <img src="/assets/images/detail_SONY STARVIS SENSOR.png" alt="SONY STARVIS Sensor"/>
                            </div>
                        </section>

                        <section className="section_7" ref={scrollRef[5]}>
                            <h3>
                                <span>AUTO NIGHT VISION</span>
                                AUTOMATED NIGHT VISION ADJUSTS<br/>
                                BRIGHTNESS AND CONTRAST TO SECURE CLARITY
                            </h3>
                            <p>
                                Smart AI system assesses light environment and finds the perfect time to switch it on.<br/>
                                Keeping you completely hand-free.
                            </p>
                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.second}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, second : 1}))}>
                                        <span>Night</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, second : 2}))}>
                                        <span>Day</span>
                                    </button>
                                </div>
                            </div>

                            <div className="tabContentBox">
                                <div className={state.second === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx400_section7_night.png" alt="night"/>
                                    </div>
                                </div>
                                <div className={state.second === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx400_section7_day.png" alt="day"/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section_8" ref={scrollRef[6]}>
                            <h3>
                                <span>Power Saving Parking Mode</span>
                                PROLONGED RECORDING TIME
                            </h3>
                            <p>Consuming 98% less power, GX400 records 2,325 hours more than standard parking mode.</p>
                            <div className="imgBox">
                                <img src="/assets/images/gx400_section8.png" alt="Power Saving Parking Mode"/>
                            </div>
                            <ul>
                                <li>* The number of recording-standby hours is based on maximum hours provided by hardwiring to fuse tap until low voltage cut-off initiates.</li>
                                <li>* Depending on dashcam’s power consumption, environmental factors, and vehicle’s battery condition actual recording standby hours may vary.</li>
                                <li>* Certain features may not be featured during Power Saving Parking Mode.</li>
                            </ul>
                        </section>

                        <section className="section_9">
                            <h3>A MINUTE LONG SURVEILLANCE</h3>
                            <p>And that is not it. Any motion caught in the next 60-seconds will be secured in a minute-long footage.</p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_A_MINUTE_LONG_SURVEILLANCE.png" alt="A MINUTE LONG SURVEILLANCE"/>
                            </div>
                            <p>
                                Vacation, business trip, or any other long absence it may be. FineVu will look after your car.
                                <mark>* Impact triggers recordings.</mark>
                            </p>
                        </section>

                        <section className="section_10" ref={scrollRef[7]}>
                            <h3>
                                <span>Record your drive with SMART TIME-LAPSE</span>
                                SMART TIME-LAPSE
                            </h3>
                            <p>
                                SMART TIME-LAPSE feature captures up to 676 minutes with no data loss.<br/>
                                When an impact is detected, FineVu will automatically save the footage in 30fps.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx400_section10.png" alt="Record your drive with SMART TIME-LAPSE"/>
                            </div>
                            <ul>
                                <li>* SMART TIME-LAPSE Specifications were measured under 32GB and compared to the default driving mode.</li>
                                <li>* Factors such as collision events may affect the storage volume.</li>
                            </ul>
                        </section>

                        <section className="section_11">
                            <h3>
                                <span>Absolute Parking Mode</span>
                                20-SECONDS OF COMPLETE EVIDENCE
                            </h3>
                            <p>FineVu’s Absolute Parking Mode secures vital 20-seconds of the accident scene.</p>
                            <div className="imgBox">
                                <img src="/assets/images/gx400_section11.png" alt="LOW VOLTAGE CUT-OFF"/>
                            </div>
                            <p>10-seconds prior to the event, and 10-seconds after the event. No detail is left out from the scene. Secure the right moment with included
                                hardwire cable.
                                <mark>* Requires hardwire cable installation</mark>
                            </p>
                        </section>

                        <section className="section_12" ref={scrollRef[8]}>
                            <h3>
                                <span>ADAS PLUS</span>
                                ADVANCED FORMAT FREE TECHNOLOGY
                            </h3>
                            <p>
                                ADAS(Advanced Driver Assistance Systems) helps safe driving with sound alerts.<br/>
                                The system evaluates its surroundings with integrated sensors, and warns driver if necessary.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_ADASPLUS.png" alt="ADAS PLUS"/>
                            </div>
                            <div className="imgBox">
                                <div>
                                    <p>
                                        <strong>Front Vehicle Motion Alert (FVMA)</strong>
                                        While stopped in traffic, the movement of stationary vehicle ahead triggers alert to driver.
                                    </p>
                                </div>
                                <img src="/assets/images/detail_ADASPLUS_FVMA.png" alt="ADAS PLUS"/>
                            </div>
                            
                            <div className="imgBox">
                                <div>
                                    <p>
                                        <strong>Lane Departure Warning System (LDWS)</strong>
                                        Alert is triggered as vehicle veers off driving lane while in motion.
                                    </p>
                                </div>
                                <img src="/assets/images/detail_ADASPLUS_LDWS.png" alt="ADAS PLUS"/>
                            </div>
                        </section>

                        <section className="section_13" ref={scrollRef[9]}>
                            <h3>
                                <span>Battery Protection</span>
                                BATTERY PROTECTION INTEGRATED
                            </h3>
                            <p>
                                FineVu is incorporated with Battery Protection System. Dashcam powers down when battery voltage falls below preset value. Select your-
                                car-specific power setting on FineVu Wi-Fi app to maximize both dashcam and car battery with a single tap.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx400_section13.png" alt="Battery Protection"/>
                            </div>
                            <p>* FineVu recommends changing the low voltage settings to ‘hybrid’ when using the ISG system.</p>
                        </section>

                        <section className="section_14" ref={scrollRef[10]}>
                            <h3>
                                <span>AI HEAT MONITORING</span>
                                SMART AI DASHCAM CONTROL
                            </h3>
                            <p>
                                If FineVu starts to overheat while at parking mode, the Al will automatically switch it to Power Saving Parking Mode.<br/>
                                When it cools down and temperature stabilizes, AI will switch it back to motion parking mode.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_AI_HEAT_MONITORING.png" alt="AI HEAT MONITORING"/>
                            </div>
                            <p>* The feature is only applicable during motion/time lapse parking mode.</p>
                        </section>

                        <section className="section_15" ref={scrollRef[11]}>
                            <h3>
                                <span>Speed Camera Alert</span>
                                FINEVU KEEPS UP WITH THE LATEST SAFETY<br/>
                                CAMERA ALERT
                            </h3>
                            <p>
                                FineVu safety camera database is always up to date. Download the quarterly updates, and get informed with FineVu’s voice & visual alerts.<br/>
                                Worry no more about accidents and speeding tickets.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_SpeedCameraAlert.png" alt="Speed Camera Alert"/>
                            </div>
                            <p>* Database is updated quarterly.</p>
                        </section>

                        <section className="section_16">
                            <h3>
                                <span>SMART SD LOCK</span>
                                SMART SD LOCK PROTECTS THE MEMORY CARD<br/>
                                ONCE MORE
                            </h3>
                            <p>
                                It is designed to turn off the power when the memory card is removed.<br/>
                                When you remove the SD Card, the device is turned off as well.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx400_section16.png" alt="SMART SD LOCK"/>
                            </div>
                        </section>
                    </div>
                    {/* <!-- Features Fin --> */}

                    <div className={`description ${tabIndex === 2 ? 'active' : ''}`}>
                        <section>
                            <h3>Product Description</h3>
                            <div className="imgBox">
                                <img src="/assets/images/fineVu_GX400_spec.jpg" alt="Product Description"/>
                            </div>
                        </section>
                    </div>
                    {/* <!-- Description Fin --> */}

                    <div className={`whatsInTheBox ${tabIndex === 3 ? 'active' : ''}`}>
                        <section>
                            <div>
                                <h3>Basic Configuration</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX400_basic_configuration.png" alt="Basic Configuration"/>
                                </div>
                                <ul>
                                    <li>※ To improve the performance of the product, its components may change without prior notice.</li>
                                    <li>※ The warranty period of the Micro SD card is 6 months.</li>
                                </ul>
                            </div>
                            <div>
                                <h3>Additional Options</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX400_additional_options.png" alt="Additional Options"/>
                                </div>
                            </div>
                        </section>
                    </div>
                    {/* <!-- What's In The Box Fin --> */}

                    <div className={`specifications ${tabIndex === 4 ? 'active' : ''}`}>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Model</th>
                                        <th>FineVu GX400</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Image Sensor</th>
                                        <td>
                                            Front : SONY STARVIS IMX335 5.1MP (HDR)<br></br>
                                            Rear : CMOS Image Sensor 2MP 
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resolution</th>
                                        <td>
                                            Front : 2560*1440P (Quad HD)<br></br>
                                            Rear : 1920*1080 (Full HD)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Frames Per Second</th>
                                        <td>
                                            Driving : 30fps, 15fps, 6fps (Smart Time Lapse)<br></br>
                                            Parking : 30fps, 15fps, 2fps (Smart Time Lapse)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Viewing Angle</th>
                                        <td>
                                            Front : 139°<br></br>
                                            Rear : 124.4°
                                        </td>
                                        <td>Diagonal</td>
                                    </tr>
                                    <tr>
                                        <th>Bitrate</th>
                                        <td>
                                            Front : 16~12 Mbps<br></br>
                                            Rear : 8~6 Mbps
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Recording Mode</th>
                                        <td>Drive / Parking / Smart Time Lapse / 15FPS / Emergency</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Display</th>
                                        <td>Non-LCD</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Acceleration Sensor</th>
                                        <td>3-Axis Acceleration Sensor</td>
                                        <td>+2g / -2g</td>
                                    </tr>
                                    <tr>
                                        <th>GPS</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Wi-Fi</th>
                                        <td>Built-In(dongle)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Storage Capacity</th>
                                        <td>FineVu Micro SD Card 128GB</td>
                                        <td>Class 10 or above, UHS-1 recommended, SDXC compatible</td>
                                    </tr>
                                    <tr>
                                        <th>Battery</th>
                                        <td>Supercapacitor</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Microphone</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Speaker</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Security LED</th>
                                        <td>
                                            Front : White<br></br>
                                            Rear : Blue
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Input Power</th>
                                        <td>12V ~ 24V DC</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Power Consumption</th>
                                        <td>
                                            Parking Mode : 4.1W<br></br>
                                            Power Saving Parking Mode : 0.06W
                                        </td>
                                        <td>2CH, GPS Off / Wi-Fi Off</td>
                                    </tr>
                                    <tr>
                                        <th>High-Temperature Cut-Off</th>
                                        <td>AI Heat Monitoring</td>
                                        <td>During parking mode</td>
                                    </tr>
                                    <tr>
                                        <th>Low Voltage Cut-Off</th>
                                        <td>Supported</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Temperature</th>
                                        <td>
                                            Operation : -20℃ ~ 70℃<br></br>
                                            Storage : -30℃ ~ 80℃
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Size(WxDxH) / Weight</th>
                                        <td>
                                            Front : 96.5 x 70.0 x 26.5 mm / 114g<br></br>
                                            Rear : 46.85 x 36.5 x 29.2 mm / 23g
                                        </td>
                                        <td>
                                            Front : Weight with no cable, cradle<br></br>
                                            Rear : Weight including the cradle
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Mobile Application</th>
                                        <td>FineVu Wi-Fi App.</td>
                                        <td>Android, IOS</td>
                                    </tr>
                                    <tr>
                                        <th>PC Viewer</th>
                                        <td>FineVu PC Player 2.0</td>
                                        <td>Windows, MAC</td>
                                    </tr>
                                    <tr>
                                        <th>Warranty</th>
                                        <td>3-Years</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>
                                            HDR, Auto Night Vision, Image Quality Setting, Emergency Recording, Parking Event Voice Alert, AI Heat Monitoring, ADAS*(FVMA&LDWS), Speed Camera Alert**,
                                            High-Temperature Cut-Off A.I Heat Monitoring, Low Voltage Cut-Off, Overwrite On/Off, Format Free 2.0,
                                            Memory Storage Allocation, In-App. Live View, Installation Angle Guide, Driving Rest Guide, Time Alarm
                                        </td>
                                        <td>ADAS and Speed Camera Alert requires GPS reception</td>
                                    </tr>
                                    <tr>
                                        <th>Regulatory Compliance</th>
                                        <td>
                                            KC (R-R-FDK-FineR052)<br></br>
                                            Model name : FineVu GX400<br></br>
                                            Date of manufacture : Marked separately<br></br>
                                            Name(Model) : Dashcam(GX400)<br></br>
                                            Manufacturer : Finedigital Inc.<br></br>
                                            Company name : Finedigital Inc.<br></br>
                                            Manufacturing country : Rep. of Korea
                                        </td>
                                        <td>
                                            Passed KS C 9990<br></br>
                                            (EMC test for vehicles, and internal combustion engines)
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Certification</th>
                                        <td className="imgArea">
                                            <div>
                                                <img src="/assets/images/table_certification.png" alt="Certification" />
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Language Supported</th>
                                        <td>
                                            English, Japanese, Russian, Polish, Vietnamese, German, Spanish, French, Hebrew, Thai, Chinese(Mandarin), Malay, Korean
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <!-- Specifications Fin --> */}

                    {tabIndex === 5 ? <ManualBox/> : ''}
                    {/* <!-- Manuals & Firmware Fin --> */}

                    <div className={`usersReview ${tabIndex === 6 ? 'active' : ''}`}>
                        <div>
                            <ul>
                                <li>
                                    <img src="/assets/images/amazon_logo.png" alt="amazon logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Saul & Vivian Batres</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                It take great high quality videos, the only thing you have to consider this is hard wire but
                                                you can purchase a seperate unit to connect to a DC outlet.
                                            </p>
                                            <p>It came with a memory card and set up was easy as well.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Brandon Lanham</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Awesome dashcam. Works amazing and has high quality display and detail. Loop records
                                                the entire time you're driving. Highly recommend.
                                            </p>
                                            <p>One person found this helpful.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- amazon Fin --> */}
                            
                            <ul>
                                <li>
                                    <img src="/assets/images/blackBoxMyCar_logo.png" alt="blackBoxMyCar logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Gus</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                I’ve had a couple of dashcams in the past. Some were very difficult to navigate. With
                                                numerous buttons and a small unit viewing window they became complicated for this ‘ol
                                                man to use. This time I wanted a simple and easy dashcam to operate.
                                            </p>
                                            <p>
                                                The FineVu GX1000 meets that criteria. It’s fairly new on the market. But found a few
                                                YouTube videos that walked you through the set up in car, how to use and review files.
                                                The simplicity of setup and operation are genius. It tucks up nicely (w/o any ugly wires)
                                                to the windshield. Plus, with the thin cables marks hardwiring a breeze.
                                            </p>
                                            <p>
                                                The video and audio quality are fantastic. Whether you’re watching on your phone or a
                                                32 inch monitor! There are many different configurations you can use capture video.
                                                Many options for notifications and parking mods. As I said, I’m a simple man and have
                                                only a notice when I start my car starts up. I’ve deselected most of the dashcams
                                                features. Why? My new 2022 vehicle already has all the safety nannies I need.
                                            </p>
                                            <p>
                                                Lastly, I like to review the videos after taking a leisurely trip through the back country
                                                roads.
                                            </p>
                                            <p>
                                                I highly recommend this unit if you’re looking for a well built, easy to setup and use dash
                                                cam.
                                            </p>
                                            <p>GWB</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Frank T.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                So far so good. I hardwired the dashcam to my truck. I also like the feature where I can
                                                manually shut the camera off with a slide switch. This prevents the vehicles battery from
                                                being drained.
                                            </p>
                                            <p>
                                                Michael at BlackboxMyCar (Canada) has been extremely helpful with answering any
                                                questions. Great customer support👏
                                            </p>
                                            <p>I've purchased from this company in the past and will continue to do so.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- blackBoxMyCar Fin --> */}

                            <ul>
                                <li>
                                    <img src="/assets/images/unavi_logo.png" alt="unavi logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Derrick Thomas</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                One of the easiest dashcameras to down load and view videos from. The videos can be
                                                Wi-Fi directly to the phone and sent via email or text. Crystal clear video and audio. Very
                                                impressed
                                            </p>
                                            <p>User picture</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Ali A.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Most dashcams I’ve seen require it to be hooked up to the cars battery which makes
                                                you tuck wires everywhere. This one plugs in and uses power straight from the rear view
                                                mirror, not having to tuck or hide any wires.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}