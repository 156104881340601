import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetProducrtByAllMutation, useGetProductByOptionMutation } from "../../../../store/user/userProductApiSlice";

export const ProductComparison = () => {
    const navigate = useNavigate()
    const [getProductByAllApi] = useGetProducrtByAllMutation()
    const [data, setData] = useState<any>([])
    const [selectProductId, setSelectProductId] = useState<any>([])
    const getList = async() => {
        const result : any = await getProductByAllApi({})
        if(result.data.Result === 'true'){
            setData(result.data.List)
        }
    }
    const handleSelectProduct = (e: any) => {
        const { id, name, checked } = e.target;
        if (checked) {
            if (selectProductId.length >= 4) {
                alert("Maximum 4 products available");
            } else {
                setSelectProductId((prev: any) => [...prev, { id: e.target.id, name: e.target.name }]);
            }
        } else {
            setSelectProductId((prev: any) => prev.filter((item: any) => item.id !== e.target.id));
        }
    }
    const handleDelProduct = (id: any) => {
        setSelectProductId((prev:any)=>prev.filter((item:any)=>item.id!==id))
    }
    const handleTransCampare = () => {
        if(selectProductId.length === 0){alert("You must select at least one."); return;}
        const formatData = selectProductId?.map((x:any)=>x.id).sort((a:any,b:any)=>a - b)
        navigate(`/productComparison/detail?product=${formatData}`)
    }
    useLayoutEffect(()=>{getList()},[])
    return(
        <>
        <div className="contentBox">
            <div>
                <aside className="left">
                    {selectProductId?.length === 0 ? 

                    <div className="no_select">
                        <span>No product is selected.</span>
                        <button onClick={handleTransCampare}>COMPARE</button>
                    </div> 

                    :

                    <div className="selectList">
                        <span>Selected Products</span>
                        <div>
                            <ul>
                                {selectProductId?.map((product:any)=>(
                                <li key={product?.id}>
                                    <span>{product?.name}</span>
                                    <button onClick={()=>handleDelProduct(product?.id)}></button>
                                </li>
                                ))}
                            </ul>
                            {/* <button onClick={()=>navigate('/productComparison/detail')}>COMPARISON</button> */}
                            <button onClick={handleTransCampare}>COMPARISON</button>
                        </div>
                    </div>

                    }

                    {/* 선택된 리스트 없을 때 */}
                </aside>
                <div className="right">
                    <h2>
                        Choose Dashcam
                        <span>You Want to Compare</span>
                    </h2>
                    <p>You can choose maximum 4 models</p>
                    <ul>
                        {data?.map((list:any) => (
                        <li className={selectProductId.some((item: any) => item.id === list?.productId) ? 'active' : ''} key={list?.productId}>
                            <div className="checkBox">
                                <input 
                                    type="checkbox" 
                                    id={list?.productId} 
                                    name={list?.productName} 
                                    onChange={handleSelectProduct}
                                    checked={selectProductId.some((item: any) => item.id === list?.productId)}
                                />
                                <label htmlFor={list?.productId}></label>
                            </div>
                            <div className="thumbBox" onClick={() => handleSelectProduct({ target: { id: list?.productId, name: list?.productName, checked: !selectProductId.some((item: any) => item.id === list?.productId) } })}>
                            {selectProductId.some((item: any) => item.id === list?.productId) ? (
                                <img className="black" src={list?.productBlackImageFile} alt={list?.productName} />
                            ) : (
                                <img className="white" src={list?.productWhiteImageFile} alt={list?.productName} />
                            )}
                            </div>
                            <span>{list?.productName}</span>
                        </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}