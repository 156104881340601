import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useGetContentsListMutation } from "../../../../../../store/admin/adminContentsApiSlice";
export interface Props {data:any}
export const ListContents:React.FC<Props> = ({data}) => {
    const navigate = useNavigate()
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr className="active" key={list?.idx} onClick={()=>navigate(`/admin/contents/content/${list?.idx}`)}>
                <td>
                    <img src={list?.pdsFile} alt="모델링 썸네일 이미지"/>
                </td>
                <td>
                    <span className="ellipsis">{list?.contents_title}</span>
                </td>
                <td>{list?.contents_mdate}</td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={list?.idx} className="toggleIpt" checked={list?.share_yn==='Y'?true:false} hidden/> 
                        <label htmlFor={list?.idx} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}