import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserBlogDetailMutation } from "../../../../store/user/contentsApiSlice";

export const BlogView = () => {
    const {blogId}:any = useParams()
    const navigate = useNavigate()
    const [getUserBlogDetailApi, {data, isSuccess, isError}] = useGetUserBlogDetailMutation()
    useEffect(()=>{getUserBlogDetailApi({idx : blogId})}, [])
    
    return(
        <>
        <div className="contentBox">
            <h2>
                FineVu <span>Blog</span>
            </h2>
            
            <div>
                <h3>
                    {data?.contents_title}
                </h3>
                <div className="dateBox">
                    <span>
                        <span>Date</span>
                        {data?.contents_mdate}
                    </span>
                    <span>
                        <span>View</span>
                        205
                    </span>
                </div>
                <div className="txtBox" dangerouslySetInnerHTML={{__html : data?.contents_desc}}>
                </div>

                <div className="btnBox">
                    <button className="blackBtn" onClick={()=>navigate(-1)}>Back</button>
                </div>
            </div>
        </div>
        </>
    )
}