import React from "react";

export const Section_6 = () => {

    return(
        <>
        <section className="mainSection_6">
            <div>
                <h2>
                    Discover how other users
                    <span>feel about our dashcams.</span>
                </h2>

                <div className="reviewBox">
                    <div>
                        <div className="top">
                            <span></span>
                            <div>
                                <span>FineVu GX1000</span>
                                <span>Completely Satisfying Purchase</span>
                            </div>
                        </div>
                        {/* <!-- top Fin --> */}
                        <div className="bottom">
                            <p>
                                I am very satisfied with the product I purchased. It is of high
                                quality and meets my expectations. The usefulness and
                                functionality of this product are impressive. It isi solidly made...
                            </p>
                            {/* <!-- 3줄 이상 말줄임 필요 --> */}
                            <div>
                                <span>review on Allegro</span>
                                <ul className="starBox">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!-- review 1 Fin --> */}
                    <div>
                        <div className="top">
                            <span></span>
                            <div>
                                <span>FineVu GX1000</span>
                                <span>Good Quality and User-Friendly</span>
                            </div>
                        </div>
                        {/* <!-- top Fin --> */}
                        <div className="bottom">
                            <p>
                                The webcam is working great so far. A big plus for
                                the unprecedentedly good user manual, with which even a
                                layman like me was able to set up this camera myself. The...
                            </p>
                            {/* <!-- 3줄 이상 말줄임 필요 --> */}
                            <div>
                                <span>Anka, review on ESSA24</span>
                                <ul className="starBox">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- bottom Fin --> */}
                    </div>
                    {/* <!-- review 2 Fin --> */}
                    <div>
                        <div className="top">
                            <span></span>
                            <div>
                                <span>FineVu GX1000</span>
                                <span>FineVu GX1000 Owner Review</span>
                            </div>
                        </div>
                        {/* <!-- top Fin --> */}
                        <div className="bottom">
                            <p>
                                I’ve had a couple of dash cams in the past. Some were very
                                difficult to navigate. With numerous buttons and a small unit
                                viewing window they became complicated for this ‘ol man to...
                            </p>
                            {/* <!-- 3줄 이상 말줄임 필요 --> */}
                            <div>
                                <span>Gus, review on BlackboxMyCar</span>
                                <ul className="starBox">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- bottom Fin --> */}
                    </div>
                    {/* <!-- review 3 Fin --> */}
                </div>
            </div>
        </section>
        </>
    )
}