import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetUserLoginMutation } from "../../../../../store/user/userApiSlice";
import { useAppDispatch } from "../../../../../store/storeHooks";
import { setuserInfo } from "../../../../../store/user/loginUserSlice";

export const WarrentyMyDashcam = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const localStorageId : any = localStorage.getItem('account')
    const [getUserLoginApi] = useGetUserLoginMutation();
    const [data, setData] = useState<{id:string,pw:string;saveCheck:boolean}>({id: localStorageId?localStorageId:'', pw : '', saveCheck : localStorageId ? true : false})
    const handleChange = (e:any) => {
        const {type, name , value, checked} = e.target;
        if(type === 'checkbox'){
            if(checked){setData((prev)=>({...prev, saveCheck : true}))}
            else{setData((prev)=>({...prev, saveCheck : false}))}
        }else{
            setData((prev:any) => ({...prev, [name] : value}))
        }
    }
    const handleLogin = async() => {
        const formData : any = new FormData()
        formData.append('dots_user_email', data?.id)
        formData.append('dots_password', data?.pw)
        const result : any = await getUserLoginApi(formData)
        if(result.data.result === true){
            if(data.saveCheck){localStorage.setItem('account' , result.data.dots_user_email)}
            else{localStorage.removeItem('account')}
            sessionStorage.setItem('userKey', result.data.idx) // session Storage
            dispatch(setuserInfo({userId : result.data.idx, account : result.data.dots_user_email, name : result.data.dots_fullname, apiId : '', productId : ''})) //store
            navigate(`/support/warrentyService/myDashcam/${result.data.idx}`);
        } else {
            alert("Check your Email or Password");
            setData((prev:any) => ({...prev, pw : ''}))
        }
    }
    const handleEnter = (e:any) => {
        if(e.key === 'Enter'){
            handleLogin()
        }
    }
    const handleTransPage = (url : string) => {
        if(location.pathname !== url){
            navigate(url)
        }
    }
    return(
        <>
        <div className="contentBox">
            <h2>My Dashcam</h2>
            <p>Try more services after logging in.</p>

            <div className="formBox login">
                <div className="inputWrap">
                    <div className="inputBox">
                        <span></span>
                        <input type="text" name={'id'} value={data?.id} onChange={handleChange} onKeyDown={handleEnter} placeholder="Email Address" required/>
                    </div>
                    <div className="inputBox">
                        <span></span>
                        <input type="password" name={'pw'} value={data?.pw} onChange={handleChange} onKeyDown={handleEnter} placeholder="Password" required/>
                    </div>
                </div>
                
                <div className="checkBox">
                    <div>
                        <input type="checkbox" onChange={handleChange} defaultChecked={localStorageId ? true : false} id="saveID"/>
                        <label htmlFor="saveID">Save ID</label>
                    </div>
                </div>

                <button className="orangeBtn" onClick={handleLogin}>
                    LOGIN
                </button>

                <div className="btnBox">
                    <button onClick={()=>handleTransPage('/support/warrentyService/findEmail')}>Find Email</button>
                    <button onClick={()=>handleTransPage('/support/warrentyService/findPassword')}>Find Password</button>
                    <button onClick={()=>handleTransPage('/support/warrentyService/prRegistration')}>Sign Up</button>
                </div>
            </div>
        </div>
        </>
    )
}