import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetManagerDetailsMutation, useSetManagerMutation, useUptManagersMutation } from "../../../../store/admin/adminStratorsApiSlice";

export const RegistAdminStrators = () => {
    const {adminId}:any = useParams()
    const navigate = useNavigate()
    const [setManagerApi] = useSetManagerMutation()
    const [uptManagersApi] = useUptManagersMutation()
    const [getMangerDetailApi] = useGetManagerDetailsMutation()
    const [data, setData] = useState<any>({manager_login : '', manager_pass : '', manager_name : '', manager_phone : '', manager_mobile : '', manager_email : ''})
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name , value} = e.target;
        setData((prev:any)=>({...prev, [name] : value}))
    }
    const getDetail = async() => {
        const result : any = await getMangerDetailApi({id : adminId})
        if(result.data.result === true){
            setData({manager_login : result.data.manager_login, manager_name : result.data.manager_name, manager_phone : result.data.manager_phone, manager_mobile : result.data.manager_mobile, manager_email : result.data.manager_email})
        }
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        if(adminId){formData.append('id', adminId)}
        formData.append('manager_login', data?.manager_login)
        formData.append('manager_pass', data?.manager_pass)
        formData.append('manager_name', data?.manager_name)
        formData.append('manager_phone', data?.manager_phone)
        formData.append('manager_mobile', data?.manager_mobile)
        formData.append('manager_email', data?.manager_email)
        if(adminId){
            const result : any = await uptManagersApi(formData)
            if(result.data.result === true){alert("수정이 완료되었습니다."); navigate(-1)}
        }else{
            const result : any = await setManagerApi(formData)
            if(result.data.result === true){alert("등록이 완료되었습니다.");navigate('/admin/setting/adminstrators')}
        }
    }
    useEffect(()=>{
        if(adminId){getDetail()}
    }, [adminId])
    return(
        <>
        <div className="contentBox administratorAdd">
            <h3>Setting</h3>
            <div className="flexWrap">
                <h4>Administrator</h4>

                <div>
                    <button className="blackBtn">목록으로</button>
                    <button className="blueBtn" onClick={handleSave}>저장하기</button>
                </div>
            </div>
            
            <ul className="upload">
                <li>
                    <label htmlFor="">아이디</label>
                    <input type="text" name="manager_login" value={data?.manager_login} id="" placeholder="사용하실 아이디를 입력해 주세요." onChange={handleChange} disabled={adminId ? true : false}/>
                </li>
                <li>
                    <label htmlFor="">비밀번호</label>
                    <input type="password" name="manager_pass" value={data?.manager_pass} id="" placeholder={adminId ? "변경할 비밀번호를 입력해 주세요." : "비밀번호를 입력해 주세요."} onChange={handleChange}/>
                </li>
                <li>
                    <label htmlFor="">이메일</label>
                    <input type="text" name="manager_email" value={data?.manager_email} id="" placeholder="이메일을 입력해 주세요." onChange={handleChange}/>
                </li>
                <li>
                    <label htmlFor="">관리자 이름</label>
                    <input type="text" name="manager_name" value={data?.manager_name} id="" placeholder="이름을 입력해 주세요." onChange={handleChange}/>
                </li>
                <li>
                    <label htmlFor="">연락처</label>
                    <input type="text" name="manager_mobile" value={data?.manager_mobile} id="" placeholder="연락처를 입력해 주세요." onChange={handleChange}/>
                </li>
                <li>
                    <label htmlFor="">휴대전화</label>
                    <input type="text" name="manager_phone" value={data?.manager_phone} id="" placeholder="휴대전화 번호를 입력해 주세요." onChange={handleChange}/>
                </li>
            </ul>
        </div>
        </>
    )
}