import React, { useRef, useState } from "react";
import { ManualBox } from "../manualsBox/products-manualsBox";
export const tabList = [
    {idx: 1, title : 'Features'},
    {idx: 2, title : 'Description'},
    {idx: 3, title : "What's In The Box"},
    {idx: 4, title : 'Specifications'},
    {idx: 5, title : 'Manuals & Firmware'},
    {idx: 6, title : "User's Review"},
]
export const GX1000 = () => {
    const [state, setState] = useState<any>({first : 1, second : 1})
    const [tabIndex, setTabIndex] = useState<number>(1)
    const scrollRef = [
        useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)
    ];
    const handleScroll = (ref : number) => {
        if(scrollRef[ref] && scrollRef[ref].current){
            scrollRef[ref].current.scrollIntoView({
                behavior : 'smooth'
            })
        }
    }
    return(
        <>
        <div className="product_view gx1000">
            <div className="bgBox" style={{backgroundImage: "url('/assets/images/banner_GX1000.png')"}}>
                <div className="centerBox">
                    <h2><span>GX</span>1000</h2>
                    <p>Perfection of Dual QHD Dashcam</p>
                    <ul>
                        <li onClick={()=>handleScroll(0)}>QHD/QHD</li>
                        <li onClick={()=>handleScroll(1)}>SONY STARVIS Sensor</li>
                        <li onClick={()=>handleScroll(2)}>Auto Night Vision</li>
                        <li onClick={()=>handleScroll(3)}>HDR</li>
                        <li onClick={()=>handleScroll(4)}>AI Damage Detection 2.0</li>
                        <li onClick={()=>handleScroll(5)}>AI Heat Monitoring</li>
                        <li onClick={()=>handleScroll(6)}>Built-in GPS & Wi-Fi</li>
                        {/* 해당 내용 없음 */}
                        <li onClick={()=>handleScroll(7)}>Power Saving Parking Mode</li>
                        <li onClick={()=>handleScroll(8)}>ADAS PLUS</li>
                        <li onClick={()=>handleScroll(9)}>Smart Time Lapse</li>
                        <li onClick={()=>handleScroll(10)}>FineVu Wi-Fi App</li>
                        <li onClick={()=>handleScroll(11)}>Speed Camera Alert</li>
                    </ul>
                </div>

                <div className="scrollDownBox">
                    <div>
                        <span></span>
                    </div>
                    <span>SCROLL</span>
                </div>
            </div>
            {/* <!-- bgBox Fin --> */}
            
            <div className="tabWrap">
                <div className="tabBtnWrap">
                    <div className="tabBtnBox">
                        {tabList.map((list:{idx:number,title:string})=>(
                            <button className={tabIndex===list?.idx?'active':''} onClick={()=>setTabIndex(list?.idx)}>
                                {list?.title}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={`features ${tabIndex === 1? 'active' : ''}`}>
                        <div className="sliderWrap">
                            <ul>
                                <li>
                                    <div className="imgBox">
                                        <img src="/assets/images/view_GX4K_1.png" alt="GX4K"/>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- slider fin --> */}
                            <div className="dotsBox">
                                <span></span>
                                <span className="active"></span>
                                <span></span>
                            </div>
                        </div>
                        {/* <!-- sliderArea Fin --> */}

                        <div className="iconBox">
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/QHDFHD.png" alt="QHD/QHD"/>
                                    </div>
                                    <span>QHD/QHD</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/Sony_STARVIS.png" alt="SONY STARVIS Sensor"/>
                                    </div>
                                    <span>
                                        SONY STARVIS<br/>
                                        Sensor
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/AutoNightVision.png" alt="Auto Night Vision"/>
                                    </div>
                                    <span>
                                        Auto<br/>
                                        Night Vision
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/hdr_icon.png" alt="HDR"/>
                                    </div>
                                    <span>HDR</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/AIDamageDetection.png" alt="AI Damage Detection 2.0"/>
                                    </div>
                                    <span>
                                        AI Damage<br/>
                                        Detection 2.0
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/AIHeatDetection.png" alt="AI Heat Detection"/>
                                    </div>
                                    <span>
                                        AI Heat<br/>
                                        Monitoring
                                    </span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/Built_inGPS.png" alt="Built-in<br/>Built-in GPS&Wi-Fi"/>
                                    </div>
                                    <span>
                                        Built-in<br/>
                                        GPS&Wi-Fi
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/PowerSavingParkingMode.png" alt="Power Saving Parking Mode"/>
                                    </div>
                                    <span>
                                        Power Saving<br/>
                                        Parking Mode
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/ADASPLUS.png" alt="ADASPLUS"/>
                                    </div>
                                    <span>
                                        ADAS PLUS
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/SmartTimeLapse.png" alt="Smart Time Lapse"/>
                                    </div>
                                    <span>
                                        Smart<br/>
                                        Time Lapse
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/FineVuWi-FiApp.png" alt="FineVu Wi-Fi App"/>
                                    </div>
                                    <span>
                                        FineVu Wi-Fi<br/>
                                        App
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/SpeedCameraAlert.png" alt="Speed Camera Alert"/>
                                    </div>
                                    <span>
                                        Speed Camera<br/>
                                        Alert
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- iconBox Fin --> */}

                        <section className="section_1" ref={scrollRef[0]}>
                            <h3>
                                <span>Crystal Clear QHD Resolution</span>
                                DUAL QHD CAPTURES EVERY MOMENT REALISTICALLY
                            </h3>

                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.second}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, second : 1}))}>
                                        <span>Front</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, second : 2}))}>
                                        <span>Rear</span>
                                    </button>
                                </div>
                            </div>

                            <div className="tabContentBox">
                                <div className={state.second === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx1000_section1_front.png" alt="Front"/>
                                    </div>
                                </div>
                                <div className={state.second === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx1000_section1_rear.png" alt="Rear"/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section_2" ref={scrollRef[1]}>
                            <h3>
                                <span>SONY STARVIS Sensor</span>
                                SONY STARVIS IMX335 PROVIDES UNIMAGINABLY<br/>
                                VIVID IMAGE IN REGARDLESS OF DAY OR NIGHT
                            </h3>
                            <div className="imgBox">
                                <img src="/assets/images/detail_SONY STARVIS SENSOR.png" alt="SONY STARVIS Sensor"/>
                            </div>
                        </section>

                        <section className="section_3" ref={scrollRef[3]}>
                            <h3>
                                <span>HDR</span>
                                OPTIMIZED WITH HIGH DYNAMIC RANGE(HDR),<br/>
                                EXPOSURE IS BALANCED FOR BOTH FRONT AND REAR<br/>
                                KEEPING ALL THE DETAILS INTACT
                            </h3>

                            <div className="imgBox">
                                <img src="/assets/images/gx1000_section3.png" alt="night"/>
                            </div>
                        </section>

                        <section className="section_4" ref={scrollRef[2]}>
                            <h3>
                                <span>AUTO NIGHT VISION</span>
                                AUTOMATED NIGHT VISION ADJUSTS<br/>
                                BRIGHTNESS AND CONTRAST TO SECURE CLARITY
                            </h3>
                            <p>
                                Smart AI system assesses light environment and finds the perfect time to switch it on.<br/>
                                Keeping you completely hand-free.
                            </p>

                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.first}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 1}))}>
                                        <span>Night</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 2}))}>
                                        <span>Day</span>
                                    </button>
                                </div>
                            </div>

                            <div className="tabContentBox">
                                <div className={state.first === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx1000_section4_night.png" alt="Night"/>
                                    </div>
                                </div>
                                <div className={state.first === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx1000_section4_day.png" alt="Day"/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section_5" ref={scrollRef[10]}>
                            <h3>
                                <span>Easy Wi-Fi Pairing with FineVu App</span>
                                CHECK THE RECORDED VIDEOS AND UPDATE<br/>
                                THE FIRMWARE ON FINEVU APP
                            </h3>
                            {/* <p>Consuming 98% less power, GX4K records 2,325 more hours than standard parking mode.</p> */}
                            <div className="imgBox">
                                <img src="/assets/images/detail_Easy_Wi-Fi.png" alt="Easy Wi-Fi Pairing with FineVu App"/>
                            </div>
                        </section>

                        <section className="section_6">
                            <h3>
                                <span>Absolute Parking Mode</span>
                                20-SECONDS OF COMPLETE EVIDENCE
                            </h3>
                            <p>FineVu’s Absolute Parking Mode secures vital 20-seconds of the accident scene.</p>
                            <div className="imgBox">
                                <img src="/assets/images/gx1000_section6.png" alt="Absolute Parking Mode"/>
                            </div>
                            <p>
                                10-seconds prior to the event, and 10-seconds after the event. No detail is left out from the scene. Secure the right<br/>
                                moment with included hardwire cable.
                                <span>* Requires hardwire cable installation</span>
                            </p>
                        </section>

                        <section className="section_7" ref={scrollRef[7]}>
                            <h3>
                                <span>Power Saving Parking Mode</span>
                                PROLONGED RECORDING TIME
                            </h3>
                            <p>Consuming 98% less power, GX1000 records 2,325 hours more than standard parking mode.</p>
                            <div className="imgBox">
                                <img src="/assets/images/gx1000_section7.png" alt="Power Saving Parking Mode"/>
                            </div>
                            <ul>
                                <li>* The number of recording-standby hours is based on maximum hours provided by hardwiring to fuse tap until low voltage cut-off initiates.</li>
                                <li>* Depending on dashcam’s power consumption, environmental factors, and vehicle’s battery condition actual recording standby hours may vary.</li>
                                <li>* Certain features, such as AI Damage Detection 2.0, may not be featured during Power Saving Parking Mode.</li>
                            </ul>
                        </section>

                        <section className="section_8">
                            <h3>
                                <span>Power Saving Parking Mode</span>
                                A MINUTE LONG SURVEILLANCE
                            </h3>
                            <p>And that is not it. Any motion caught in the next 60-seconds will be secured in a minute-long footage.</p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_A_MINUTE_LONG_SURVEILLANCE.png" alt="Power Saving Parking Mode"/>
                            </div>
                            <p>
                                Vacation, business trip, or any other long absence it may be. FineVu will look after your car.
                                <span>* Requires hardwire cable installation</span>
                            </p>
                        </section>

                        <section className="section_9" ref={scrollRef[9]}>
                            <h3>
                                <span>Record your drive with SMART TIME-LAPSE</span>
                                SMART TIME LAPSE
                            </h3>
                            <p>
                                SMART TIME-LAPSE feature captures up to 676 minutes with no data loss. When an impact is detected, FineVu will automatically save the footage in
                                30fps.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx1000_section9.png" alt="Record your drive with SMART TIME-LAPSE"/>
                            </div>
                            <ul>
                                <li>* SMART TIME-LAPSE Specifications were measured under 32GB and compared to the default driving mode.</li>
                                <li>* Factors such as collision events may affect the storage volume</li>
                            </ul>
                        </section>

                        <section className="section_10" ref={scrollRef[5]}>
                            <h3>
                                <span>AI HEAT MONITORING</span>
                                SMART AI DASHCAM CONTROL
                            </h3>
                            <p>
                                If FineVu starts to overheat while at parking mode, the AI will automatically switch it to Power Saving Parking Mode. When it cools down and
                                temperature stabilizes, AI will switch it back to motion parking mode.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_AI_HEAT_MONITORING.png" alt="AI HEAT MONITORING"/>
                            </div>
                            <p> * The feature is only applicable during motion/time lapse parking mode.</p>
                        </section>

                        <section className="section_11">
                            <h3>
                                <span>Battery Protection</span>
                                BATTERY PROTECTION INTEGRATED
                            </h3>
                            <p>
                                FineVu is incorporated with Battery Protection System. Dashcam powers down when battery voltage falls below preset value. Select your-car-
                                specific power setting on FineVu Wi-Fi app to maximize both dashcam and car battery with a single tap.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx1000_section11.png" alt="LOW VOLTAGE CUT-OFF"/>
                            </div>
                            <p>* FineVu recommends changing the low voltage settings to 'hybrid' when using the ISG system.</p>
                        </section>

                        <section className="section_12" ref={scrollRef[4]}>
                            <h3>
                                <span>AI DAMAGE DETECTION 2.0</span>
                                AI FALSE ALARM FILTER
                            </h3>
                            <p>
                                FineVu's machine learning AI went through 3 million and more tests. FineVu AI will filter false impacts and alert genuine collisions. The AI sections your
                                vehicle into 8-impact areas and alerts the impact in 3-different levels. FineVu will pinpoint where the impact occurred, how strong it was, and how
                                many times.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx1000_section12.png" alt="AI DAMAGE DETECTION 2.0"/>
                            </div>
                            <ul>
                                <li>* If the impact areas cannot be detected, the notification display may not be seen or a certain icon() may be displayed.</li>
                                <li>* In the case of non-accidental events, there will be no separate notifications. There may be errors in the guidance depending on the usage environment.</li>
                                <li>* When the Power Saving Parking Mode function is set, this function may not work.</li>
                                <li>* The measured impact when hitting or shaking the dashcam may differ from the actual impact of accidents.</li>
                            </ul>
                        </section>

                        <section className="section_13" ref={scrollRef[11]}>
                            <h3>
                                <span>Speed Camera Alert</span>
                                AI FALSE ALARM FILTER
                            </h3>
                            <p>
                                FineVu safety camera database is always up to date. Download the quarterly updates, and get informed with FineVu's voice & visual alerts. Worry no
                                more about accidents and speeding tickets.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_SpeedCameraAlert.png" alt="Speed Camera Alert"/>
                            </div>
                            <p>* Database is updated quarterly</p>
                        </section>

                        <section className="section_14" ref={scrollRef[8]}>
                            <h3>
                                <span>ADAS PLUS</span>
                                ADVANCED DRIVER SAFETY WARNING SYSTEMS<br/>
                                KEEP YOU SAFE ON THE ROAD
                            </h3>
                            <p>
                                ADAS(Advanced Driver Assistance Systems) helps safe driving with sound alerts.<br/>
                                The system evaluates its surroundings with integrated sensors, and warns driver if necessary.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_ADASPLUS.png" alt="ADAS PLUS"/>
                            </div>
                            <div className="imgBox">
                                <div>
                                    <p>
                                        <strong>Front Vehicle Motion Alert (FVMA)</strong>
                                        While stopped in traffic, the movement of stationary vehicle ahead triggers alert to driver.
                                    </p>
                                </div>
                                <img src="/assets/images/detail_ADASPLUS_FVMA.png" alt="Front Vehicle Motion Alert (FVMA)"/>
                            </div>
                            <div className="imgBox">
                                <div>
                                    <p>
                                        <strong>Lane Departure Warning System (LDWS)</strong>
                                        Alert is triggered as vehicle veers off driving lane while in motion.
                                    </p>
                                </div>
                                <img src="/assets/images/detail_ADASPLUS_LDWS.png" alt="Lane Departure Warning System (LDWS)"/>
                            </div>
                        </section>
                    </div>
                    {/* <!-- Features Fin --> */}

                    <div className={`description ${tabIndex === 2 ? 'active' : ''}`}>
                        <section>
                            <h3>Product Description</h3>
                            <div className="imgBox">
                                <img src="/assets/images/fineVu_GX1000_spec.png" alt="Product Description"/>
                            </div>
                        </section>
                    </div>
                    {/* <!-- Description Fin --> */}

                    <div className={`whatsInTheBox ${tabIndex === 3 ? 'active' : ''}`}>
                        <section>
                            <div>
                                <h3>Basic Configuration</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX1000_basic_configuration.png" alt="Basic Configuration"/>
                                </div>
                                <ul>
                                    <li>※ To improve the performance of the product, its components may change without prior notice.</li>
                                    <li>※ The warranty period of the Micro SD card is 6 months.</li>
                                </ul>
                            </div>
                            <div>
                                <h3>Additional Options</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX1000_additional_options.png" alt="Additional Options"/>
                                </div>
                            </div>
                        </section>
                    </div>
                    {/* <!-- What's In The Box Fin --> */}

                    <div className={`specifications ${tabIndex === 4 ? 'active' : ''}`}>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Model</th>
                                        <th>FineVu GX1000</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Image Sensor</th>
                                        <td>
                                            Front : SONY STARVIS IMX335 5.14MP (HDR)<br></br>
                                            Rear : SONY STARVIS IMX335 5.14MP (HDR)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resolution</th>
                                        <td>
                                            Front : 2560*1440P (Quad HD)<br></br>
                                            Rear : 2560*1440P (Quad HD)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Frames Per Second</th>
                                        <td>
                                            Driving : 30fps, 15fps, 6fps (Smart Time Lapse)<br></br>
                                            Parking : 30fps, 15fps, 2fps (Smart Time Lapse)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Viewing Angle</th>
                                        <td>
                                            Front : 139°<br></br>
                                            Rear : 141°
                                        </td>
                                        <td>Diagonal</td>
                                    </tr>
                                    <tr>
                                        <th>Bitrate</th>
                                        <td>
                                            Front : 16~12 Mbps<br></br>
                                            Rear : 16~12 Mbps
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Recording Mode</th>
                                        <td>Drive / Parking / Smart Time Lapse / 15FPS / Emergency</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Display</th>
                                        <td>Non-LCD</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Acceleration Sensor</th>
                                        <td>3-Axis Acceleration Sensor</td>
                                        <td>+2g / -2g</td>
                                    </tr>
                                    <tr>
                                        <th>GPS</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Wi-Fi</th>
                                        <td>Built-In(dongle)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Storage Capacity</th>
                                        <td>FineVu Micro SD Card 128GB</td>
                                        <td>Class 10 or above, UHS-1 recommended, SDXC compatible</td>
                                    </tr>
                                    <tr>
                                        <th>Battery</th>
                                        <td>Supercapacitor</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Microphone</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Speaker</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Security LED</th>
                                        <td>
                                            Front : White<br></br>
                                            Rear : Blue
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Input Power</th>
                                        <td>12V ~ 24V DC</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Power Consumption</th>
                                        <td>
                                            Parking Mode : 5W<br></br>
                                            Power Saving Parking Mode : 0.06W
                                        </td>
                                        <td>2CH, GPS Off / Wi-Fi Off</td>
                                    </tr>
                                    <tr>
                                        <th>High-Temperature Cut-Off</th>
                                        <td>AI Heat Monitoring</td>
                                        <td>During parking mode</td>
                                    </tr>
                                    <tr>
                                        <th>Low Voltage Cut-Off</th>
                                        <td>Supported</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Temperature</th>
                                        <td>
                                            Operation : -20℃ ~ 70℃<br></br>
                                            Storage : -30℃ ~ 80℃
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Size(WxDxH) / Weight</th>
                                        <td>
                                            Front : 96.5 x 70.0 x 26.5 mm / 114g<br></br>
                                            Rear : 46.85 x 36.5 x 29.2 mm / 18g
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Mobile Application</th>
                                        <td>FineVu Wi-Fi App.</td>
                                        <td>Android, IOS</td>
                                    </tr>
                                    <tr>
                                        <th>PC Viewer</th>
                                        <td>FineVu PC Player 2.0</td>
                                        <td>Windows, MAC</td>
                                    </tr>
                                    <tr>
                                        <th>Warranty</th>
                                        <td>3-Years</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>
                                            HDR, Auto Night Vision, Image Quality Setting, Emergency Recording, Parking Event Voice Alert, AI Heat Monitoring, AI Damage Detection 2.0, ADAS*(FVMA&LDWS),
                                            Speed Camera Alert**,  High-Temperature Cut-Off A.I Heat Monitoring, Low Voltage Cut-Off, Overwrite On/Off, Format Free 2.0, Memory Storage Allocation, 
                                            In-App. Live View, Installation Angle Guide, Driving Rest Guide, Time Alarm
                                        </td>
                                        <td>ADAS and Speed Camera Alert requires GPS reception</td>
                                    </tr>
                                    <tr>
                                        <th>Regulatory Compliance</th>
                                        <td>
                                            KC (R-R-FDK-FineR038)<br></br>
                                            Model name : FineVu GX1000<br></br>
                                            Date of manufacture : Marked separately<br></br>
                                            Name(Model) : Dashcam(GX1000)<br></br>
                                            Manufacturer : Finedigital Inc.<br></br>
                                            Company name : Finedigital Inc.<br></br>
                                            Manufacturing country : Rep. of Korea
                                        </td>
                                        <td>
                                            Passed KS C 9990<br></br>
                                            (EMC test for vehicles, and internal combustion engines)
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Certification</th>
                                        <td className="imgArea">
                                            <div>
                                                <img src="/assets/images/table_certification.png" alt="Certification" />
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Language Supported</th>
                                        <td>
                                            English, Japanese, Russian, Polish, Vietnamese, German, Spanish, French, Hebrew, Thai, Chinese(Mandarin), Malay, Korean
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <!-- Specifications Fin --> */}

                    {tabIndex === 5 ? <ManualBox/> : ''}
                    {/* <!-- Manuals & Firmware Fin --> */}

                    <div className={`usersReview ${tabIndex === 6 ? 'active' : ''}`}>
                        <div>
                            <ul>
                                <li>
                                    <img src="/assets/images/amazon_logo.png" alt="amazon logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Saul & Vivian Batres</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                It take great high quality videos, the only thing you have to consider this is hard wire but
                                                you can purchase a seperate unit to connect to a DC outlet.
                                            </p>
                                            <p>It came with a memory card and set up was easy as well.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Brandon Lanham</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Awesome dashcam. Works amazing and has high quality display and detail. Loop records
                                                the entire time you're driving. Highly recommend.
                                            </p>
                                            <p>One person found this helpful.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- amazon Fin --> */}
                            
                            <ul>
                                <li>
                                    <img src="/assets/images/blackBoxMyCar_logo.png" alt="blackBoxMyCar logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Gus</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                I’ve had a couple of dashcams in the past. Some were very difficult to navigate. With
                                                numerous buttons and a small unit viewing window they became complicated for this ‘ol
                                                man to use. This time I wanted a simple and easy dashcam to operate.
                                            </p>
                                            <p>
                                                The FineVu GX1000 meets that criteria. It’s fairly new on the market. But found a few
                                                YouTube videos that walked you through the set up in car, how to use and review files.
                                                The simplicity of setup and operation are genius. It tucks up nicely (w/o any ugly wires)
                                                to the windshield. Plus, with the thin cables marks hardwiring a breeze.
                                            </p>
                                            <p>
                                                The video and audio quality are fantastic. Whether you’re watching on your phone or a
                                                32 inch monitor! There are many different configurations you can use capture video.
                                                Many options for notifications and parking mods. As I said, I’m a simple man and have
                                                only a notice when I start my car starts up. I’ve deselected most of the dashcams
                                                features. Why? My new 2022 vehicle already has all the safety nannies I need.
                                            </p>
                                            <p>
                                                Lastly, I like to review the videos after taking a leisurely trip through the back country
                                                roads.
                                            </p>
                                            <p>
                                                I highly recommend this unit if you’re looking for a well built, easy to setup and use dash
                                                cam.
                                            </p>
                                            <p>GWB</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Frank T.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                So far so good. I hardwired the dashcam to my truck. I also like the feature where I can
                                                manually shut the camera off with a slide switch. This prevents the vehicles battery from
                                                being drained.
                                            </p>
                                            <p>
                                                Michael at BlackboxMyCar (Canada) has been extremely helpful with answering any
                                                questions. Great customer support👏
                                            </p>
                                            <p>I've purchased from this company in the past and will continue to do so.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- blackBoxMyCar Fin --> */}

                            <ul>
                                <li>
                                    <img src="/assets/images/unavi_logo.png" alt="unavi logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Derrick Thomas</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                One of the easiest dash cameras to down load and view videos from. The videos can be
                                                Wi-Fi directly to the phone and sent via email or text. Crystal clear video and audio. Very
                                                impressed
                                            </p>
                                            <p>User picture</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Ali A.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Most dashcams I’ve seen require it to be hooked up to the cars battery which makes
                                                you tuck wires everywhere. This one plugs in and uses power straight from the rear view
                                                mirror, not having to tuck or hide any wires.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}