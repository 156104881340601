import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetManagerListMutation } from "../../../../../store/admin/adminStratorsApiSlice";

export const ListAdminStrators = () => {
    const navigate = useNavigate()
    const {adminId} = useParams()
    const [data, setData] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [getManagerListApi] = useGetManagerListMutation()
    const [param, setParam] = useState<any>({page : 1, size : 10, sortOrder : 'desc', sortColumn : 'manager_login', keyword : ''})
    const getList = async() => {
        const result : any = await getManagerListApi(param)
        if(result.data.result === true){
            setData(result.data.list); setTotalCount(result.data.total_cnt)
        }
    }
    useEffect(()=>{
        getList()
    }, [])
    return(
        <>
        <div className="contentBox administrator">
            <h3>Setting</h3>
            <div className="flexWrap">
                <h4>
                    Administrator
                    <span className="content_count">{totalCount}</span>
                </h4>
            </div>
            
            <table className="table_hover">
                <thead>
                    <tr>
                        <th>
                            <span className="sortWrap">
                                관리자 이름
                                <span className="sort desc"></span>
                            </span>
                        </th>
                        <th>
                            <span className="sortWrap">
                                아이디
                                <span className="sort desc"></span>
                            </span>
                        </th>
                        <th>
                            <span className="sortWrap">
                                연락처
                                <span className="sort desc"></span>
                            </span>
                        </th>
                        <th>
                            <span className="sortWrap">
                                휴대전화
                                <span className="sort"></span>
                            </span>
                        </th>
                        <th>
                            <span className="sortWrap">
                                이메일
                                <span className="sort"></span>
                            </span>
                        </th>
                        <th>수정</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((list:any) => (
                    <tr onClick={()=>navigate(`/admin/setting/adminstrators/${list?.id}`)}>
                        <td>
                            <span className="ellipsis">{list?.manager_name}</span>
                        </td>
                        <td>
                            <span className="ellipsis">{list?.manager_login}</span>
                        </td>
                        <td>{list?.manager_mobile}</td>
                        <td>{list?.manager_phone}</td>
                        <td>{list?.manager_email}</td>
                        <td>
                            <button></button>
                        </td>
                    </tr>
                    ))}
                    
                </tbody>
            </table>
            <button className="addNewBtn">Add New</button>

            <div className="pagerBox">
                <p>Showing 1 to 10 of 98 entries</p>
                <div>
                    <button className="left"></button>
                    <ol>
                        <li>1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>5</li>
                        <li className="dot">...</li>
                        <li>10</li>
                    </ol>
                    <button className="right"></button>
                </div>
            </div>

        </div>
        </>
    )
}