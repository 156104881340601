import React from "react";

export const DownloadPcPlayer = () => {

    return(
        <>
        <div className="contentBox">
            <h2>
                Download
                <span>FineVu PC Player</span>
            </h2>
            <p>
                FineVu PC player is an easy-to-use PC software of FineVu dashcam.<br/>
                You can playback, screenshot, enlarge the images with full resolution<br/>
                and monitor the video records conveniently on your PC.
            </p>

            <div className="btnWrap">
                <div className="btnBox">
                    <span>General Dashcam PC Player</span>
                    <a href="https://en.finevu.com/upload/pc_player/FINEVu_Player_2.0.zip" download>Download for Windows</a>
                    <a href="https://en.finevu.com/upload/pc_player/FineVuPlayer_1.0.3.pkg" download>Download for Mac</a>
                </div>
                <div className="btnBox">
                    <span>4K Dashcam PC Player</span>
                    <a href="https://en.finevu.com/upload/pc_player/4k/FineVuPlayer_4k.zip" download>Download for Windows</a>
                    <a href="https://en.finevu.com/upload/pc_player/4k/FineVuPlayer_1.0.3.pkg" download>Download for Mac</a>
                </div>
            </div>

            <div className="imgArea">
                <img src="/assets/images/fineVu_pcPlayer.png" alt="fineVu PC Player"/>
            </div>

            <div className="txtBox">
                <span>Instructions</span>
                <ol>
                    <li>
                        <span>1</span>
                        Download viewer file for your corresponding operating system of your computer.
                    </li>
                    <li>
                        <span>2</span>
                        Unzip the file on your computer and open the setup file.
                    </li>
                    <li>
                        <span>3</span>
                        Follow the installation steps.
                    </li>
                    <li>
                        <span>4</span>
                        Once the installation is complete, insert your memory card into your computer to automatically add video files
                        to the playlist.
                    </li>
                </ol>
            </div>

        </div>
        </>
    )
}