import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Inquiry_Popup } from "../../../components/user/main/inquiry_popUp";
import { ScrollBtn } from "../../../components/user/main/scrollBtn";
import { Main } from "../main/main";
import { SubPage } from "../subPage";
import { DetailComparison } from "../subPage/productComparison/detailComparison";
import Footer from "./footer/footer";
import Header from "./header/header";
import Header_Pc from "./header/pc";
import { useGetUserInfoMutation } from "../../../store/user/userApiSlice";
import { useAppDispatch } from "../../../store/storeHooks";
import { setuserInfo } from "../../../store/user/loginUserSlice";
import { PrivacyPolicy } from "../subPage/privacyPolicy";


const LayOut = () => {
    const {productId, supportType, supportName, id, blogId} = useParams()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const storedId = sessionStorage.getItem('userKey');
    const [getUserInfoApi] = useGetUserInfoMutation()
    const getUserInfo = async() => {
        const result : any = await getUserInfoApi({idx : storedId})
        if(result.data.result === true){
            dispatch(setuserInfo({userId:result.data.idx, account:result.data.dots_user_email, name:result.data.dots_fullname, productId:'', apiId:''}))
        }
    }
    useEffect(()=>{
        getUserInfo()
    }, [dispatch])
    useEffect(()=>{
        window.scrollTo({top : 0, behavior : 'auto'})
    },[location.pathname])
    return(
        <>
        <div className="user_wrap">
            
            <Header/>
            {location.pathname === '/' ? <Main/> :''}
            {location.pathname === `/products` ? <SubPage/> :''}
            {location.pathname === `/products/${productId}` ? <SubPage/> :''}
            {location.pathname === `/productComparison` ? <SubPage/> :''}
            {location.pathname === `/productComparison/detail` ? <DetailComparison/> :''}
            {location.pathname === `/support/${supportType}` ? <SubPage/> :''}
            {location.pathname === `/support/${supportType}/${supportName}` ? <SubPage/> :''}
            {location.pathname === `/support/${supportType}/${supportName}/${id}` ? <SubPage/> :''}
            {location.pathname === `/blog` ? <SubPage/> :''}
            {location.pathname === `/blog/${blogId}` ? <SubPage/> :''}
            {location.pathname === `/fineVu` ? <SubPage/> :''}
            {location.pathname === `/location` ? <SubPage/> :''}
            {location.pathname === `/sitemap` ? <SubPage/> :''}
            {location.pathname === '/privacy-policy' ? <SubPage/> : ''}

            <div className="inquiryWrap">
                <ScrollBtn/>
            </div>

            <Footer/>
        </div>
        </>
    )
}

export default LayOut;