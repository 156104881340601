import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackDrop } from "../../../components/backDrop/backDrop";
import { useGetManagerLoginMutation } from "../../../store/admin/adminStratorsApiSlice";
import { setAdminInfo } from "../../../store/admin/adminInfoSlice";
import { useAppDispatch } from "../../../store/storeHooks";

export const AdminLogin = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [backDrop, setBackDrop] = useState<boolean>(false)
    const [data, setData] = useState<{id:string,pw:string,saveCheck:boolean}>({id : '', pw : '', saveCheck : false})
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {type, name, value} = e.target;
        if(type === 'checkbox'){
            setData((prev)=>({...prev, saveCheck : false ? true : false}))
        } else {
            setData((prev)=>({...prev, [name] : value}))
        }
    }
    const [getManagerLogin] = useGetManagerLoginMutation()
    const handleLogin = async() => {
        setBackDrop(true)
        const formData : any = new FormData()
        formData.append('manager_login', data?.id)
        formData.append('manager_pass', data?.pw)
        const result:any = await getManagerLogin(formData)
        if(result.data.result === true){
            navigate('/admin/members/member')
            sessionStorage.setItem('key', result.data.id)
            dispatch(setAdminInfo({adminId : result.data.id, account : result.data.manager_login, name : result.data.manager_name, groupId : ''}))
        }else{
            alert("아이디 및 비밀번호를 확인해 주세요.")
            setBackDrop(false)
        }
    }
    const handleEnter = (e:any) => {if(e.key==='Enter'){handleLogin()}}
    return(
        <>
        <div id="admin_wrap">
            <div className="admin_loginBox">
                <h2>FineVu ADMIN</h2>
                <p>로그인 후 더욱 다양한 서비스를 이용해 보세요.</p>
                <div>
                    <div className="inputList">
                        <label htmlFor="">
                            <img src="/assets/images/form_id.png" alt="login"/>
                        </label>
                        <input type="text" name="id" id="id" value={data.id} placeholder="아이디" onChange={handleChange} onKeyDown={handleEnter}/>
                    </div>
                    <div className="inputList">
                        <label htmlFor="">
                            <img src="/assets/images/form_pw.png" alt="password"/>
                        </label>
                        <input type="password" name="pw" id="pw" value={data.pw} placeholder="비밀번호" onChange={handleChange} onKeyDown={handleEnter}/>
                    </div>
                    <div className="inputCheckBox">
                        <input type="checkbox" name="id_save" id="id_save" onChange={handleChange}/>
                        <label htmlFor="id_save">아이디 저장</label>
                    </div>

                    <button className="admin_loginBtn" onClick={handleLogin}>로그인</button>
                </div>
            </div>
        </div>
        <BackDrop open={backDrop}/>
        </>
    )
}