import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SingleContentsBox } from "../../../../components/admin/contents/singleContentsBox";
import { SingleImageUpload } from "../../../../components/admin/contents/singleImageUpload";
import { SingleInputBox } from "../../../../components/admin/contents/singleInputBox";
import { useGetContentsDetailMutation, useSetContentsMutation, useUptContentsMutation } from "../../../../store/admin/adminContentsApiSlice";

export const RegistContents = () => {
    const {contentsId}:any = useParams()
    const navigate = useNavigate()
    const [getContentsDetailApi] = useGetContentsDetailMutation()
    const [uptContentsApi] = useUptContentsMutation()
    const [setContentsApi] = useSetContentsMutation()
    const [data , setData] = useState<any>({subject : '', thumnailImage : '', excerpt : '', contents : '', previewImage : ''})
    const getDetail = async() => {
        const result : any = await getContentsDetailApi({idx : contentsId})
        if(result.data.result === true){setData((prev:any)=>({...prev,
        subject : result.data.contents_title, previewImage : result.data.pdsFile,
        excerpt : result.data.extract_place, contents : result.data.contents_desc}))}
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        if(contentsId){formData.append('idx', contentsId)}
        formData.append('title', data.subject)
        formData.append('extract', data.excerpt)
        formData.append('desc' , data.contents)
        if(data?.thumnailImage){formData.append('fsAttached1PdfFile', data.thumnailImage)}
        if(contentsId){
            const result : any = await uptContentsApi(formData)
            if(result.data.result === true){alert("수정이 완료되었습니다.");navigate(-1)}
        }else {
            const result : any = await setContentsApi(formData)
            if(result.data.result === true){
                alert("등록이 완료되었습니다."); navigate('/admin/contents/content');
            }
        }
    }
    useEffect(()=>{
        if(contentsId){getDetail()}
    },[contentsId])
    return(
        <>
        <div className="contentBox upload">
            <h3>Content</h3>
            <div className="flexWrap">
                <div className="flexBox">
                    <h4>
                        List of Content
                    </h4>
                </div>
                <div>
                    <button className="blackBtn">목록으로</button>
                    <button className="blueBtn" onClick={handleSave}>저장하기</button>
                </div>
            </div>
            
            <table>
                <tbody>
                    <SingleInputBox setData={setData} name={'subject'} value={data?.subject} placeholder={'보도자료 제목을 입력해 주세요.'} title={'제목'}/>
                    <SingleImageUpload title={'썸네일'} name={'thumnailImage'} setData={setData} imageUrl={data.previewImage}/>
                    <SingleInputBox setData={setData} name={'excerpt'} value={data?.excerpt} placeholder={'콘텐츠 요약본을 입력해 주세요.'} title={'발췌'}/>
                    <SingleContentsBox title={'본문'} name={'contents'} setData={setData} value={data?.contents}/>
                </tbody>
            </table>

        </div>
        </>
    )
}