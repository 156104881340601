import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../store/storeHooks";
import { useGetProductByOptionMutation } from "../../../../store/user/userProductApiSlice";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

export const Section_4 = () =>{
    const setting = {
        dots : false,
        arrows : false,
        infinte : false,
        speed : 500,
        slidesToScroll: 1,
        //centerMode : true,
        slidesToShow: window.innerWidth > 768 ? 3 : 1,
        vertical: false,
        centerPadding: '5px',
        // autoplay: true,
        // autoplaySpeed: 3000,
        // centerMode: true,
    }
    const navigate = useNavigate()
    const slickRef = useRef<any>(null)
    const menuInfo = useAppSelector(state=>state.menuInfo?.menuInfo)
    // const [channel, setChannel] = useState<any>(parseInt(menuInfo?.channel[0]?.menuId))
    const [channel, setChannel] = useState<any>(2)
    const [data, setData] = useState<any>([])
    const [getProductByOptionApi] = useGetProductByOptionMutation()
    const getList = async() => {
        const param : any = {channelType : channel , resolutionType : '', featuresType : '', page : 1, size : 10}
        const result : any = await getProductByOptionApi(param)
        if(result){
            setData(result.data.List)
        }
    }
    const prev = useCallback(()=>slickRef.current.slickPrev(), [])
    const next = useCallback(()=>slickRef.current.slickNext(), [])
    // useEffect(()=>{setChannel(parseInt(menuInfo?.channel[0]?.menuId))},[menuInfo])
    useEffect(()=>{getList()}, [channel])
    return(
        <>
        <section className="mainSection_4">
            <div>
                <h2>
                    All types of
                    <span>dashcam are in FineVu.</span>
                </h2>
                
                <div className="slide_tabBtnWrap">
                    <div className={`slide_tabBtnBox active_${channel}`}>
                        {/* {menuInfo?.channel?.map((ch:any)=>(
                            <button key={ch?.menuId} onClick={()=>setChannel(parseInt(ch?.menuId))}>
                                <span>{ch?.menuName}</span>
                            </button>
                        ))} */}
                        <button onClick={()=>setChannel(1)}>
                            <span>1CH</span>
                        </button>
                        <button onClick={()=>setChannel(2)}>
                            <span>2CH</span>
                        </button>
                        {/* <button>
                            <span></span>
                        </button> */}
                    </div>
                </div>

                <div className="sliderWrap">
                    <ul>
                        <Slider {...setting} ref={slickRef}>
                        {data?.map((list:any, index:number) => (
                        <li key={list?.productId} onClick={()=>navigate(`/products/${list?.productId}`)}>
                            <div className="imgBox" style={{cursor:'pointer'}}>
                                <img src={list?.productWhiteImageFile} alt="product i/mage"/>
                            </div>
                            <span>{list?.productName}</span>
                        </li>
                        ))}
                        </Slider>
                    </ul>
                    {/* <!-- slider Fin --> */}

                    {/* <div className="btnBox">
                        <button onClick={()=>prev()}></button>
                        <button onClick={()=>next()}></button>
                    </div> */}
                    <button className="left" onClick={()=>prev()}></button>
                    <button className="right" onClick={()=>next()}></button>
                </div>
            </div>
        </section>
        </>
    )
}