import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Paginate } from "../../../../components/paginate/paginate_admin";
import { useGetUserBlogListMutation } from "../../../../store/user/contentsApiSlice";

export const Blog = () => {
    const searchRef = useRef<any>(null)
    const location = useLocation()
    const page = new URLSearchParams(location.search).get('page') || 1;
    const keyword = new URLSearchParams(location.search).get('keyword') || '';
    const navigate = useNavigate()
    const [getUserBlogListApi] = useGetUserBlogListMutation()
    const [data, setData] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [param, setParam] = useState<any>({page : page, size : 9, keyword : keyword})
    const getList = async() => {
        const result : any = await getUserBlogListApi(param)
        if(result?.data?.result === true){setData(result?.data?.list); setTotalCount(result.data.total_cnt)}
    }
    const handlePage = (pageNumber:number) => {navigate(`/blog?page=${pageNumber}&keyword=${keyword}`)}
    const handleSearch = () => {navigate(`/blog?page=${page}&keyword=${searchRef.current.value}`)}
    const handleEnter = (e:any) => {if(e.key==='Enter'){handleSearch()}}
    useEffect(()=>{setParam((prev: any) => ({...prev, page : page, keyword : keyword}))}, [page,keyword])
    useEffect(()=>{getList()},[param.page, param.keyword])
    return(
        <>
        <div className="contentBox">
            <h2>
                FineVu <span>Blog</span>
            </h2>
            
            <div className="search_box">
                <input type="text" placeholder="search" defaultValue={keyword} ref={searchRef} onKeyDown={handleEnter}/>
                <button type="button" className="ic-button" onClick={handleSearch}></button>
            </div>

            <div className="searchResultBox">
                {data?.length === 0 ? 

                <div className="none">
                    <p>
                        <strong>No search results for 'keyword'.</strong>
                        Please check the search term and try again.
                    </p>
                </div> 

                :

                <div>
                    <div className="keywordBox">
                        {keyword !== ''? <p>Search results for <span>'{keyword}'</span></p> : '' }
                        <span>Total {totalCount}</span>
                    </div>
                    <ul>
                        {data?.map((list:any) => (
                        <li onClick={()=>navigate(`/blog/${list.idx}`)}>
                            <div className="thumbBox">
                                <img src={list?.pdsFile} alt="blog list thumbnail"/>
                            </div>
                            <p>
                                {list?.contents_title}
                            </p>
                            {/* <!-- 두 줄 이상 말줄임 필요 --> */}
                            <span>{list?.contents_mdate}</span>
                        </li>
                        ))}
                    </ul>
                </div>

                }
                {data?.length === 0 
                ? '' :
                <div className="pagerBox">
                    <Paginate page={1} size={9} totalCount={totalCount} setPage={handlePage}/>
                </div>
                }
            </div>
        </div>
        </>
    )
}