import React from "react";
import { useNavigate } from "react-router-dom";

export const DashboardHeader = () => {
    const navigate = useNavigate()
    const handleLogout = () => {
        navigate('/admin')
    }
    return(
        <>
        <div className="dotsHeader">
            <div className="header">
                <div>
                    <div>
                        <div>
                            <span>Admin</span>
                            <i className="fa-regular fa-chevron-down"></i>
                        </div>
                    </div>
                    <div>
                        <span>홈페이지 바로가기<i className="fa-regular fa-chevron-right"></i></span>
                        <button onClick={handleLogout}>로그아웃</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}