import React from "react";
import { useNavigate } from "react-router-dom";

export const Section_1 = () => {
    const navigate = useNavigate()
    return(
        <>
        <section className="mainSection_1">
            
            <div className="imgBox">
                <img className="pc" src="/assets/images/mainBG.jpg" alt="mainBG" onClick={()=>navigate('/products/36')}/>
                <img className="mo" src="/assets/images/mainBG_mo.png" alt="mainBG" onClick={()=>navigate('/products/36')}/>
            </div>

        </section>
        </>
    )
}